<template>
  <v-row>
    <v-col class="px-0">
      <v-card outlined>
        <v-sheet class="d-flex py-2">
          <v-row class="pl-1 align-center" no-gutters>
            <v-col :cols="8" :md="2" class="py-0">
              <v-select
                :items="curLanguages"
                outlined
                class="ml-0 mb-2"
                hide-details
                v-model="language"
                @change="langauageChanged"
                label="Language"
              />
            </v-col>
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            <v-col
              :cols="12"
              :md="7"
              :lg="6"
              class="py-0 pr-1"
              v-if="showSettings"
            >
              <v-row no-gutters>
                <v-col :cols="6" :md="3" class="py-0">
                  <v-select
                    :items="themes"
                    outlined
                    class="ml-0 font-weight-light"
                    hide-details
                    v-model="theme"
                    @change="updateTheme"
                    label="Theme"
                  />
                </v-col>
                <v-col :cols="6" :md="3" class="py-0 pl-1">
                  <v-select
                    :items="keyMaps"
                    outlined
                    class="ml-0 font-weight-light"
                    hide-details
                    v-model="keyMap"
                    @change="updateKeyMap"
                    label="Key map"
                  />
                </v-col>
                <v-col :cols="3" class="py-0 pl-1 hidden-sm-and-down">
                  <v-select
                    :items="fontSizes"
                    outlined
                    class="ml-0 font-weight-light"
                    hide-details
                    v-model="fontSize"
                    @change="updateFontSize"
                    label="Font size"
                  />
                </v-col>
                <v-col :cols="3" class="py-0 pl-1 hidden-sm-and-down">
                  <v-select
                    :items="tabSizes"
                    outlined
                    class="ml-0 font-weight-light"
                    hide-details
                    v-model="tabSize"
                    @change="updateTabSize"
                    label="Tab size"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="2" align="right">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="accent" large v-bind="attrs" v-on="on">
                    <v-icon class="text-right" @click="removeCode">{{
                      mdiCached
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>Reset Code</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    :color="autoCompleteColor"
                    large
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="text-right" @click="toggleAutocomplete">{{
                      mdiToggle
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>Auto complete</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    :color="closingBracketsColor"
                    large
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="text-right" @click="toggleBracket">{{
                      mdiCodeParentheses
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>Auto closing bracket</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="gray" large v-bind="attrs" v-on="on">
                    <v-icon class="text-right" @click="toggleSettings">{{
                      mdiAccountCogOutline
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>Settings</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-sheet>
        <v-divider></v-divider>
        <v-row>
          <v-col class="py-0 mt-5">
            <!-- Monaco Editor will attach here -->
            <div ref="monacoEditor" style="height: 400px; width: 100%"></div>
          </v-col>
        </v-row>
        <!-- Custom Status Bar -->
        <v-sheet class="status-bar pt-3" color="primary" dark>
          <v-row align="center" no-gutters>
            <v-col class="text-left" cols="6">
              <v-icon left>mdi-code-tags</v-icon>
              <span>Ln: {{ lineNumber }}, Col: {{ column }}</span>
            </v-col>

            <v-col class="text-center" cols="3">
              <span>Key Map: {{ keyMap }}</span>
            </v-col>

            <v-col class="text-right" cols="3" v-if="keyMap === 'vim'">
              <span>Vim Mode: {{ currentVimMode }}</span>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card>
      <Autocomplete ref="autocomplete" />
    </v-col>
  </v-row>
</template>

<script>
import * as monaco from 'monaco-editor'
import { initVimMode } from 'monaco-vim'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import Autocomplete from './Autocomplete'
import { mapEditorConfig } from '@/utils/helper'

import {
  mdiCached,
  mdiLightbulb,
  mdiAccountCogOutline,
  mdiCodeParentheses,
} from '@mdi/js'

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    evaluationType: {
      type: Number,
      required: false,
    },
    allowedLanguages: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      lineNumber: 1,
      column: 1,
      vimMode: null,
      currentVimMode: 'normal', // Track the current Vim mode
      showVimModeNotification: false, // To control the notification visibility
      decorationIds: [],
      mdiCached,
      mdiToggle: mdiLightbulb,
      mdiAccountCogOutline,
      mdiCodeParentheses,
      autoCompleteColor: 'yellow',
      closingBracketsColor: 'yellow',
      enableAutocomplete: true,
      autoCloseBrackets: true,
      showSettings: true,
      tabSize: 4,
      theme: 'vs-dark',
      language: null,
      fontSize: 'normal',
      keyMap: 'default',
      autoCompleteSettings: {
        quickSuggestions: true,
        suggestOnTriggerCharacters: true,
        parameterHints: { enabled: true },
        wordBasedSuggestions: true,
      },
      acceptSuggestionOnEnter: 'on',
      snippetSuggestions: 'top',
      defaultConfig: {
        fontSize: 'normal',
        tabSize: 4,
        theme: 'vs-dark',
        keyMap: 'default',
        autoComplete: true,
      },
      languages: [
        { text: 'C++', value: 'CPP_17' },
        { text: 'Java', value: 'JAVA_11' },
        { text: 'Kotlin', value: 'KOTLIN_1_3' },
        { text: 'Python', value: 'PYTHON_3' },
        { text: 'MySQL 8', value: 'MY_SQL_8' },
        { text: 'JavaScript', value: 'JAVASCRIPT' },
        { text: 'Ruby', value: 'RUBY' },
        { text: 'C#', value: 'CSHARP' },
        { text: 'TypeScript', value: 'TYPESCRIPT' },
        { text: 'Go', value: 'GO' },
        { text: 'Dart', value: 'DART' },
        { text: 'R', value: 'R' },
        { text: 'PHP', value: 'PHP' },
      ],
      monacoLanguageMap: {
        CPP_17: 'cpp',
        JAVA_11: 'java',
        KOTLIN_1_3: 'kotlin',
        PYTHON_3: 'python',
        MY_SQL_8: 'mysql',
        JAVASCRIPT: 'javascript',
        RUBY: 'ruby',
        CSHARP: 'csharp',
        TYPESCRIPT: 'typescript',
        GO: 'go',
        DART: 'dart',
        R: 'r',
        PHP: 'php',
      },
      fontSizeMap: {
        small: 12,
        normal: 14,
        large: 18,
      },

      themes: [
        { text: 'Dark', value: 'vs-dark' },
        { text: 'Light', value: 'vs-light' },
      ],
      keyMaps: [
        { text: 'Vim', value: 'vim' },
        // { text: 'Emacs', value: 'emacs' }, to support need to uprade to vue3 and vuecli5
        // { text: 'Sublime', value: 'sublime' }, need to add manully
        { text: 'Default', value: 'default' },
      ],
      fontSizes: [
        { text: 'Small', value: 'small' },
        { text: 'Normal', value: 'normal' },
        { text: 'Large', value: 'large' },
      ],
      tabSizes: [
        { text: '2 Spaces', value: 2 },
        { text: '4 Spaces', value: 4 },
      ],
      editor: null,
    }
  },
  components: {
    Autocomplete,
  },
  computed: {
    ...mapState('user', ['userPreference', 'user']),
    ...mapGetters('user', ['languageKeys']),
    ...mapGetters('judge', ['langaugeOptions']),
    curLanguages() {
      if (this.evaluationType == 1) return [this.languages[4]]
      if (this.evaluationType == 2) return [this.languages[3]]
      console.log('SET LANGUAGE')
      if (!this.allowedLanguages) {
        var cloneLangs = [...this.languages]
        cloneLangs.splice(4, 1)
        console.log('....', cloneLangs)
        return cloneLangs
      }
      console.log('Allowed langs are', this.allowedLanguages)
      let cur = this.languages.filter((lg) => {
        return (
          this.allowedLanguages.length == 0 ||
          this.allowedLanguages.find((l) => this.languageKeys[l] == lg.value)
        )
      })
      console.log('Cur langs are', cur)
      return cur
    },
    editorConfig() {
      return mapEditorConfig(true, this.userPreference?.getEditorConfig() || {})
    },
  },
  watch: {
    value(newValue) {
      if (this.editor && newValue !== this.editor.getValue()) {
        this.editor.setValue(newValue) // Update editor when value changes
      }
    },
  },
  methods: {
    ...mapMutations('user', ['setEditorConfig']),
    ...mapActions('user', ['saveUserPreference']),
    langauageChanged(newLang) {
      console.log('Language changed:', newLang, this.langaugeOptions)
      console.log(
        'Editor config before langauge update:',
        this.editorConfig.toObject(),
      )
      this.editorConfig.setLanguage(this.langaugeOptions[newLang])
      console.log(
        'Editor config after langauge update:',
        this.editorConfig.toObject(),
      )
      this.saveEditorConfig()
      this.$emit('setLanguage', newLang)
    },
    updateTheme(newTheme) {
      console.log('Theme changed:', newTheme)
      this.editorConfig.setTheme(newTheme)
      this.saveEditorConfig()
    },

    updateKeyMap(newKeyMap) {
      this.keyMap = newKeyMap
      this.initializeKeyMap(this.editor, newKeyMap)
      this.editorConfig.setKeyMap(newKeyMap)
      this.saveEditorConfig()
    },
    updateFontSize(newFontSize) {
      console.log('Font size changed:', newFontSize)
      this.editorConfig.setFontSize(newFontSize)
      this.saveEditorConfig()
    },
    updateTabSize(newTabSize) {
      console.log('Tab size changed:', newTabSize)
      this.editorConfig.setTabSize(newTabSize)
      this.saveEditorConfig()
    },
    //TODO: remove duplicate assignments
    syncConfig(editorConfig) {
      console.log('Syncing editor with config:', editorConfig.toObject())
      if (!editorConfig) return
      this.theme =
        editorConfig.getTheme() || this.theme || this.defaultConfig.theme
      this.keyMap =
        editorConfig.getKeyMap() || this.keyMap || this.defaultConfig.keyMap
      this.fontSize =
        editorConfig.getFontSize() ||
        this.fontSize ||
        this.defaultConfig.fontSize
      this.tabSize =
        editorConfig.getTabSize() || this.tabSize || this.defaultConfig.tabSize
      this.enableAutocomplete =
        editorConfig.getAutoComplete() ||
        this.enableAutocomplete ||
        this.defaultConfig.autoComplete
    },
    initializeMonacoEditor(editorConfig) {
      console.log(
        'Initializing Monaco editor with configuration:',
        editorConfig.toObject(),
      )
      const monacoLang = this.monacoLanguageMap[this.language] || 'javascript'

      this.editor = monaco.editor.create(this.$refs.monacoEditor, {
        value: this.value || '',
        language: monacoLang,
        theme:
          editorConfig.getTheme() || this.theme || this.defaultConfig.theme,
        fontSize:
          this.fontSizeMap[
            editorConfig.getFontSize() ||
              this.fontSize ||
              this.defaultConfig.fontSize
          ],
        tabSize:
          editorConfig.getTabSize() ||
          this.tabSize ||
          this.defaultConfig.tabSize,
        automaticLayout: true,
        autoClosingBrackets: this.autoCloseBrackets ? 'always' : 'never',
        quickSuggestions: this.autoCompleteSettings.quickSuggestions,
        suggestOnTriggerCharacters:
          this.autoCompleteSettings.suggestOnTriggerCharacters,
        wordBasedSuggestions: this.autoCompleteSettings.wordBasedSuggestions,
        parameterHints: this.autoCompleteSettings.parameterHints,
        snippetSuggestions: this.snippetSuggestions,
        acceptSuggestionOnEnter: this.acceptSuggestionOnEnter,
        folding: true,
        foldingStrategy: 'auto',
        minimap: { enabled: false },
      })

      // Listen for cursor position changes
      this.editor.onDidChangeCursorPosition((e) => {
        this.lineNumber = e.position.lineNumber
        this.column = e.position.column
      })

      this.initializeKeyMap(this.editor, this.keyMap)
      // Add initial decoration
      // this.addDecoration()

      // Handle content change in the editor
      this.editor.onDidChangeModelContent(() => {
        const content = this.editor.getValue()
        this.$emit('input', content)
      })

      console.log('language ..', monacoLang)
      if (monacoLang === 'JAVA_11')
        this.setupJavaAutocomplete() // Call the function to add Java snippets
      else if (monacoLang === 'CPP_17')
        this.setupCppAutocomplete() // Call the function to add C++ snippets
      else if (monacoLang === 'PYTHON_3') this.setupPythonAutocomplete() // Call the function to add Python snippets
    },

    // // Method to add decoration to the editor
    // addDecoration() {
    //   console.log('Adding decoration to the editor')

    //   if (!this.editor) {
    //     console.error('Editor instance is not available')
    //     return
    //   }

    //   // Define the decoration with range and styling options
    //   const decorations = [
    //     {
    //       range: new monaco.Range(1, 1, 1, 10), // Apply decoration from line 1, char 1 to char 10
    //       options: {
    //         inlineClassName: 'my-custom-decoration', // Use custom CSS class
    //       },
    //     },
    //   ]

    //   // Apply the decoration and store its ID
    //   this.decorationIds = this.editor.deltaDecorations(
    //     this.decorationIds,
    //     decorations,
    //   )

    //   console.log('Decoration IDs:', this.decorationIds)
    // },

    // // Method to remove decoration (optional)
    // removeDecoration() {
    //   if (this.decorationIds.length) {
    //     this.editor.deltaDecorations(this.decorationIds, []) // Remove decorations
    //     this.decorationIds = []
    //   }
    // },

    initializeKeyMap(editor, keyMap) {
      // Dispose of previous Vim mode if it exists
      if (this.vimMode) {
        this.vimMode.dispose()
      }

      // Check for keyMap and initialize Vim mode if applicable
      if (keyMap === 'vim') {
        this.currentVimMode = 'normal'
        this.vimMode = initVimMode(editor, this.$refs.statusBar)
        console.log('Vim mode initialized:', this.vimMode)
        // Event listener for Vim mode changes
        const modeChangeListener = (event) => {
          console.log('Vim mode change:', event)
          console.log('Vim mode change:', event.mode)
          this.currentVimMode = event.mode
          console.log('Current Vim mode:', this.currentVimMode)
        }

        // Register mode change listener
        this.vimMode.on('vim-mode-change', modeChangeListener)
      } else {
        this.vimMode = null
        this.currentVimMode = null // Reset Vim mode when not in Vim keyMap
      }
    },
    saveEditorConfig() {
      this.syncConfig(this.editorConfig)
      this.updateEditorOptions(this.editorConfig)
      console.log('Saving editor config:', this.editorConfig.toObject())
      if (this.user) {
        // Save user preference if user is logged in for maintaing state if window refreshed
        console.log('Saving user editor config:', this.language, this.theme)
        this.saveUserPreference()
      }
    },
    updateEditorOptions(config) {
      console.log('Updating editor options:', config.toObject())
      this.editor.updateOptions({
        theme: config.getTheme() || this.theme || this.defaultConfig.theme,
        fontSize:
          this.fontSizeMap[
            config.getFontSize() || this.fontSize || this.defaultConfig.fontSize
          ],
        tabSize:
          config.getTabSize() || this.tabSize || this.defaultConfig.tabSize,
        autoClosingBrackets: this.autoCloseBrackets ? 'always' : 'never',
        acceptSuggestionOnEnter: this.acceptSuggestionOnEnter,
        snippetSuggestions: this.snippetSuggestions,
        quickSuggestions: this.autoCompleteSettings.quickSuggestions,
        suggestOnTriggerCharacters:
          this.autoCompleteSettings.suggestOnTriggerCharacters,
        wordBasedSuggestions: this.autoCompleteSettings.wordBasedSuggestions,
        parameterHints: this.autoCompleteSettings.parameterHints,
      })
      console.log('Editor options updated', this.editor)
      const monacoLang = this.monacoLanguageMap[this.language]
      const model = this.editor.getModel()
      if (model) {
        monaco.editor.setModelLanguage(model, monacoLang)
      } else {
        console.error('Model is undefined')
      }
    },
    removeCode() {
      this.$emit('resetCode', (response, type) => {
        console.log('Remove code', response, type)
        this.showMessage(response, type)
      })
    },
    showMessage(response, type) {
      console.log('Code reset successfully')
      this.$store.dispatch('notifs/addNotif', {
        text: response,
        type: type,
      })
    },

    toggleAutocomplete() {
      this.enableAutocomplete = !this.enableAutocomplete
      this.autoCompleteColor = this.enableAutocomplete ? 'yellow' : 'gray'
      if (this.enableAutocomplete) {
        this.autoCompleteSettings = {
          quickSuggestions: true,
          suggestOnTriggerCharacters: true,
          parameterHints: { enabled: true },
          wordBasedSuggestions: true,
        }
      } else {
        this.autoCompleteSettings = {
          quickSuggestions: false,
          suggestOnTriggerCharacters: false,
          parameterHints: { enabled: false },
          wordBasedSuggestions: false,
        }
      }
      this.updateEditorOptions(this.editorConfig)
    },
    toggleSettings() {
      this.showSettings = !this.showSettings
    },
    toggleBracket() {
      this.autoCloseBrackets = !this.autoCloseBrackets
      this.closingBracketsColor = this.autoCloseBrackets ? 'yellow' : 'gray'
      this.updateEditorOptions(this.editorConfig)
    },
  },

  mounted() {
    console.log('Editor mounted')
    console.log('Editor config:', this.editorConfig.toObject())
    this.language =
      this.languageKeys[this.editorConfig.getLanguage()] || 'CPP_17'
    console.log('Mounted Language:', this.language)
    this.$emit('setLanguage', this.language)
    this.syncConfig(this.editorConfig)
    this.initializeMonacoEditor(this.editorConfig)
    if (this.$refs.autocomplete) {
      this.$refs.autocomplete.setupJavaAutocomplete()
      this.$refs.autocomplete.setupCppAutocomplete()
      this.$refs.autocomplete.setupPythonAutocomplete()
    }
  },

  beforeDestroy() {
    if (this.editor) {
      this.editor.dispose() // Cleanup editor instance
    }
  },
}
</script>

<style scoped>
.vue-monaco-editor {
  width: 100%;
  height: 100%;
}

/* Custom styling for the decoration */
.my-custom-decoration {
  background-color: yellow; /* Highlight background color */
  color: red; /* Change text color */
  font-weight: bold; /* Make text bold */
}

.status-bar {
  height: 40px;
  padding: 5px;
}
</style>
